<template>
  <div class='wrap'>
    <TopCard ref="TopCardRef"
             text="学情分析" />
    <div class="chartd_warp">

      <a-form label-width="80px">
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="本次时间">
              <a-range-picker v-model:value="value1"
                              style="width:100%"
                              value-format="YYYY-MM-DD HH:mm:ss"
                              :show-time="{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'),dayjs('23:59:59', 'HH:mm:ss') ]}"
                              separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="上次时间">
              <a-range-picker v-model:value="value2"
                              style="width:100%"
                              :show-time="{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'),dayjs('23:59:59', 'HH:mm:ss') ]}"
                              value-format="YYYY-MM-DD HH:mm:ss"
                              separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">

              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <div class="explain">
        说明：1.学情报告指对学生的做卷情况、各科弱点、科目整体情况进行分析
      </div>
      <div class="one_warp">
        <div class="title">
          <div>
            一、各类型训练的训练次数与得分率
          </div>
          <!-- <div>
            训练次数及得分率统计
          </div> -->
        </div>
        <div class="biao_warp">
          <div class="sciring_average_warp">
            <SciringAverage ref="SciringAverageRef" />
          </div>
          <div class="center_biaoge">
            <Score ref="ScoreRef" />
          </div>
          <div class="right_biaoge">
            <Contrast ref="ContrastRef" />
          </div>
        </div>
      </div>

      <div class="one_warp">
        <div class="title">
          <div>
            二、各题型训练次数与得分率
          </div>
          <!-- <div>
            各题型训练及得分率统计
          </div> -->
        </div>
        <div class="tabs">
          <NumberAndScore ref="numberAndScoreRef" />
        </div>
      </div>

      <div class="one_warp">
        <div class="title">
          <div>
            三、薄弱章节列表
          </div>
          <!-- <div>
            各章节训练及得分率统计
          </div> -->
        </div>
        <div class="tabs">
          <Chapter ref="chapterRef" />
        </div>
      </div>

      <div class="one_warp">
        <div class="title">
          <div>
            四、薄弱知识点列表
          </div>
          <!-- <div>
            各知识点训练及得分率统计
          </div> -->
        </div>
        <div class="tabs">
          <WeakKnowledge ref="weakKnowledgeRef" />
        </div>
      </div>

      <div class="one_warp">
        <div class="title">
          <div>
            五、弱点训练效果图
          </div>
          <!-- <div>
            前一次薄弱考点与本次对比
          </div> -->
          <div class='chart_legend'>
            <div><span></span>前一次得分率（%）</div>
            <div><span></span>本次得分率（%）</div>
          </div>

        </div>
        <div class="tabs">
          <KnowledgeContrast ref="knowledgeContrastRef" />
        </div>
      </div>

      <div class="one_warp">
        <div class="title"
             style="height:70px">
          <div>
            六、总结
          </div>
        </div>
        <div class="tabs">
          <Summary ref="summaryRef" />
        </div>
      </div>

      <br />
    </div>
  </div>
</template>

<script setup>
import TopCard from '@/views/components/top_card/index.vue'
import SciringAverage from './components/scoringAverage.vue'
import Score from './components/score.vue'
import Contrast from './components/contrast.vue'
import NumberAndScore from './numberAndScore/index.vue'
import Chapter from './chapter/index.vue'
import WeakKnowledge from './weakKnowledge/index.vue'
import KnowledgeContrast from './knowledge/index.vue'
import Summary from './summary/index.vue'


import { instance } from '@/utils/http.js'
import { getUserSubject } from '@/api/subject.js'
import dayjs, { Dayjs } from 'dayjs'
import { ref, watch, provide, nextTick, onMounted } from 'vue'
import { reactive } from '@vue/reactivity'
import { useStore } from 'vuex'

let subjectList = ref([])
let info = reactive({})
let value1 = ref([])//本次时间段
let value2 = ref([])//上一次时间段

let SciringAverageRef = ref()
let ScoreRef = ref()
let ContrastRef = ref()
let numberAndScoreRef = ref()
let chapterRef = ref()
let weakKnowledgeRef = ref()
let knowledgeContrastRef = ref()
let summaryRef = ref()

const store = useStore()

watch(value1, (newValue, oldValue) => {
  publicFun()
})
watch(value2, (newValue, oldValue) => {
  publicFun()
})

const publicFun = () => {
  if (!value2.value || !value1.value) {
    return
  }
  getcishuInfo()
  setSubjectId()
  /*  numberAndScoreRef.value.value1 = value1
   numberAndScoreRef.value.value2 = value2 */
  numberAndScoreRef.value.getInfo()
  chapterRef.value.getInfo()
  weakKnowledgeRef.value.getInfo()
  knowledgeContrastRef.value.getInfo()
  summaryRef.value.getInfo()
}


const getcishuInfo = async () => {
  const { data } = await instance({
    url: '/api/v1/user/learning_analysis',
    method: 'get',
    params: {
      this_weak_start_time: value1.value ? value1.value[0] : '',
      this_weak_end_time: value1.value ? value1.value[1] : '',
      last_weak_start_time: value2.value ? value2.value[0] : '',
      last_weak_end_time: value2.value ? value2.value[1] : ''
    }
  })
  //// debugger
  // console.log("data", data)
  SciringAverageRef.value.getInfo(data.module_data)
  ScoreRef.value.getInfo(data.module_data)
  ContrastRef.value.getInfo(data.module_data)
}

// 获取科目
subjectList.value = store.state.subject.subjectList
const getSubjectList = async () => {
  if (!subjectList.value.length) {
    const { data } = await getUserSubject()
    subjectList.value = data.list
    store.commit('subject/setSubjectList', data.list)
  }
  setSubjectId()
}
const setSubjectId = () => {
  // // debugger
  numberAndScoreRef.value.activeName = subjectList.value[0].subject_id + ''
  weakKnowledgeRef.value.activeName = subjectList.value[0].subject_id + ''

  chapterRef.value.activeName = subjectList.value[0].subject_id + ''
  knowledgeContrastRef.value.activeName = subjectList.value[0].subject_id + ''
  summaryRef.value.activeName = subjectList.value[0].subject_id + ''
}

provide('subjectList', subjectList)
provide('value1', value1)
provide('value2', value2)
onMounted(() => {
  nextTick(() => {
    getcishuInfo()
    getSubjectList()
  })
})
/*export default {
  data () {
    return {
      subjectList: [],
      info: {},
      value1: [],
      value2: [],
    }
  },

  components: {
     SciringAverage,
    // Score, Contrast, NumberAndScore, WeakKnowledge, KnowledgeContrast, Summary, Chapter
  },
  created () {
    this.getcishuInfo()
    this.getSubjectList()
  },
  watch: {
    value1 () {
      this.publicFun()
    },
    value2 () {
      this.publicFun()
    },
  },

  methods: {
    dayjs,
    publicFun () {
      if (!this.value2 || !this.value1) {
        return
      }
      this.getcishuInfo()
      this.setSubjectId()
      // this.$refs.numberAndScoreRef.getInfo()
      // this.$refs.weakKnowledgeRef.getInfo()
      // this.$refs.chapterRef.getInfo()
      // this.$refs.summaryRef.getInfo()
      // this.$refs.knowledgeContrastRef.getInfo()
    },
    getcishuInfo () {
      instance({
        url: '/api/v1/user/learning_analysis',
        method: 'get',
        params: {
          this_weak_start_time: this.value1 ? this.value1[0] : '',
          this_weak_end_time: this.value1 ? this.value1[1] : '',
          last_weak_start_time: this.value2 ? this.value2[0] : '',
          last_weak_end_time: this.value2 ? this.value2[1] : ''
        }
      }).then(res => {
        //// debugger
        //this.$refs.SciringAverageRef.getInfo(res.data.module_data)
        // this.$refs.ScoreRef.getInfo(res.data.module_data)
        // this.$refs.ContrastRef.getInfo(res.data.module_data)
      })
    },
    getSubjectList () {
      instance({
        url: '/api/v1/public/subjects',
        method: 'get'
      }).then(res => {
        console.log('res', res)
        this.subjectList = res.data.list
        this.setSubjectId()
      })
    },
    setSubjectId () {
      // // debugger
      //   this.$refs.numberAndScoreRef.activeName = this.subjectList[0].subject_id + ''
      //   this.$refs.weakKnowledgeRef.activeName = this.subjectList[0].subject_id + ''

      //   this.$refs.chapterRef.activeName = this.subjectList[0].subject_id + ''
      //   this.$refs.knowledgeContrastRef.activeName = this.subjectList[0].subject_id + ''
      //   this.$refs.summaryRef.activeName = this.subjectList[0].subject_id + ''
    }
  }
}*/
</script>

<style lang="scss" scoped>
.wrap {
  background: #f6f6f6;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.chartd_warp {
  padding: 20px 40rem;
  // overflow: scroll;
  //height: calc(100vh - 40px);

  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-size: 14px;
  }
  .explain {
    font-size: 24rem;
    // font-family: PingFang-SC-Medium, PingFang-SC;
    color: #333333;
    line-height: 33rem;
    padding-left: 10rem;
    margin-top: 30rem;
    margin-bottom: 30rem;
    p {
      text-indent: 3em;
    }
  }
}
.biao_warp {
  display: flex;
  padding-top: 20rem;
  padding-bottom: 18rem;
  .sciring_average_warp {
    width: 41.16%;
  }
  .center_biaoge {
    border-right: 1px solid #e5e5e5;
    flex: 1;
  }
  .right_biaoge {
    width: 31.36%;
  }
}

.one_warp {
  width: 99%;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgba(234, 234, 234, 0.5);
  border-radius: 30rem;
  border: 1px solid #ececec;
  margin: 0 auto;
  padding: 20rem;
  margin-bottom: 40rem;
  .title {
    // height: 80px;
    overflow: hidden;
    position: relative;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24rem;
      padding: 10rem 0 20rem;
      font-weight: bold;
      &:nth-child(1) {
        color: #000000;
      }
      &:nth-child(2) {
        color: #999999;
      }
    }
    .chart_legend {
      position: absolute;
      bottom: 0;
      top: 10rem;
      right: 20rem;
      div {
        margin-right: 50rem;
        color: #333;
        font-weight: 500;
        &:nth-child(2) span {
          background: #2196f3;
        }
      }
      span {
        width: 20rem;
        height: 20rem;
        background: #f2be39;
        border-radius: 50%;
        margin-right: 16rem;
      }
    }
  }
}
.info {
  position: relative;
  div {
    position: absolute;
    right: 30px;
    height: 30px;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 5px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-top: -3px;
      &:nth-child(1) {
        background: #f2be39;
      }
      &:nth-child(2) {
        background: #2196f3;
      }
    }
  }
}

:deep .ant-form-item-label > label {
  font-size: 24rem;
  height: 60rem;
}
:deep .ant-picker {
  padding: 4px 11px 4px;
  font-size: 22rem;
}
:deep .ant-picker-input > input {
  padding: 4px 11px;
  font-size: 24rem;
}
:deep
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}
:deep .ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #ccc;
  border-radius: 5rem;
  overflow: hidden;
}

// ::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }
</style>